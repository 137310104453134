
export default {
  name: "TestimonialsSection",
  props: ['zone'],
  computed: {
    groupedTestimonials() {
      // Logic to group testimonials into rows
      // This is a simple example and can be adjusted based on your specific requirements
      const rows = [];
      const itemsPerRow = 3; // Adjust as needed

      for (let i = 0; i < this.zone.testimonials.length; i += itemsPerRow) {
        rows.push(this.zone.testimonials.slice(i, i + itemsPerRow));
      }

      return rows;
    },
  },
}
